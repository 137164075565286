import React, { ComponentProps, useState } from "react";
import Slideshow from "../../common/components/Slideshow";
import { IMAGE_TRANSFORM_TYPES } from "./Image";
import GalleryOverlay from "./GalleryOverlay";

type Props = {
  media: ComponentProps<typeof Slideshow>["media"];
  className: string;
  overlayHeader?: ComponentProps<typeof GalleryOverlay>["header"];
  mainSlideshowImageSizes: IMAGE_TRANSFORM_TYPES;
  overlaySlideshowImageSizes: IMAGE_TRANSFORM_TYPES;
  title?: string;
  reserved?: boolean;
  caption?: string;
  hideCaption?: boolean;
};

const Gallery: React.FC<Props> = ({
  media,
  className,
  overlayHeader,
  mainSlideshowImageSizes,
  overlaySlideshowImageSizes,
  title,
  reserved,
  caption,
  hideCaption,
}) => {
  const [overlayOpenIndex, setIsOverlayOpen] = useState<null | number>(null);
  return (
    <>
      <Slideshow
        caption={caption}
        className={className}
        hideCaption={hideCaption}
        imageSizes={mainSlideshowImageSizes}
        media={media}
        reserved={reserved}
        title={title}
        showGalleryOverlay={index => {
          setIsOverlayOpen(index ?? null);
        }}
      />
      <GalleryOverlay
        className={className}
        header={overlayHeader}
        imageSizes={overlaySlideshowImageSizes}
        media={media}
        openIndex={overlayOpenIndex ?? undefined}
        closeOverlay={() => {
          setIsOverlayOpen(null);
        }}
      />
    </>
  );
};

export default Gallery;

import React from "react";
import { ArrayParam } from "use-query-params";
import i18next from "i18next";
import SearchableMultiOptionSelect from "../../../visual-components/components/form/SearchableMultiOptionSelect";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import { SelectItem } from "../../../visual-components/components/form/SingleOptionSelect";
import { FilterItem } from "./FilterItem";
import FilterBlock from "./FilterBlock";

type Props = {
  title: string;
  queryParam: string;
  data: FilterItem[];
  fixOpen: boolean;
  conjunctive: boolean | undefined;
};

const MultiSelectFilter: React.FC<Props> = ({ title, queryParam, data, fixOpen, conjunctive }) => {
  const [persistedTags, setTags] = useFilterQueryParam(queryParam, ArrayParam);
  const appliedTags: string[] = persistedTags || [];
  const hasAppliedTags = appliedTags.length > 0;

  // todo: this should contain all information for selectItem and should be used for "options"
  const selectItemMap = data.reduce<Record<string, SelectItem>>((agg, item) => {
    agg[item.value] = {
      value: item.value,
      name: item.name,
      invisible: item.invisible,
    };
    return agg;
  }, {});

  const onChange = (value: string, checked: boolean) => {
    if (checked) {
      setTags([...appliedTags, value]);
    } else {
      setTags(appliedTags.filter(item => item !== value));
    }
  };

  return (
    <FilterBlock
      bg
      className="filter__select"
      count={appliedTags.length}
      title={title}
      reset={() => {
        setTags([]);
      }}
    >
      <SearchableMultiOptionSelect
        fixOpen={fixOpen}
        placeholder={i18next.t("ALL")}
        values={appliedTags.map(referenceId => selectItemMap[referenceId])}
        options={data.map(({ value, name, occurrence, invisible }) => ({
          value,
          name,
          inactive: occurrence === 0 && (!hasAppliedTags || conjunctive),
          invisible,
        }))}
        onChange={onChange}
      />
    </FilterBlock>
  );
};

export default MultiSelectFilter;

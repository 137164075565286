import classNames from "classnames";
import React from "react";
import IconHeroSliderNav from "../../../template/public/assets/img/layout/inline/icon-hero-slider-nav.svg?react";

const VisualHeroSliderNav: React.FC<{
  title?: string | null;
  displayDuration: number;
  animating: boolean;
  active: boolean;
  onClick: () => void;
}> = ({ title, displayDuration, animating, active, onClick }) => {
  const style = {
    "--slider-circle-animation-duration": `${displayDuration}ms`,
  } as React.CSSProperties;

  return (
    <button
      className={classNames("slider-nav__item", { active: active, animating: active && animating })}
      style={style}
      onClick={onClick}
    >
      <IconHeroSliderNav />
      {title ? <div className="slider-nav__tooltip">{title}</div> : null}
    </button>
  );
};

export default VisualHeroSliderNav;

import { useEffect, useMemo, useRef } from "react";
import { QueryParamProvider } from "use-query-params";
import classNames from "classnames";
import { useGetBannerQuery } from "../../../modules/hygraph/services/bannerApi";
import { useBannerWhitelabelConfig } from "../../core/components/WhitelabelConfig";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import useStableFunction from "../../../modules/common/hooks/useStableFunction";
import { CarCardContextType } from "../../../modules/vehicle/components/CarCard";
import { setUtmParams, UtmCampaignType } from "../../core/services/setUtmParams";
import WhitelabelBannerContainer from "../../core/components/WhitelabelBannerContainer";
import VisualHeroSliderContainer from "../../../modules/cms/components/HeroSliderContainer";
import { useHeroSlider } from "../../../modules/cms/hooks/useHeroSlider";
import VisualHeroSliderNav from "../../../modules/cms/components/HeroSliderNav";
import { BannerSlide as BannerSlideType } from "../../../modules/hygraph/vo";
import { logger } from "../../../modules/common/scripts/logger";
import BannerSlide from "./BannerSlide";
import { BannerAdapter } from "./BannerAdapter";
import { SlideContextProvider } from "./SlideContext";

const ADVERTISING_ROW_HEIGHT = "--advertising-row-height";

const BannerInner: React.FC<{ slides: BannerSlideType[]; utmSource?: string }> = ({ slides, utmSource }) => {
  const { searchPath, homePath } = usePathBuilder();

  const { activeSlide, isAnimating, navigateToSlide, pauseAnimating, resumeAnimating } = useHeroSlider(slides);

  const wrapRef = useRef<HTMLDivElement>(null);
  const slidesRef = useRef<Map<number, HTMLDivElement>>(new Map());

  const setSlideRef = (key: number, node: HTMLDivElement | null) => {
    if (node && !slidesRef.current.get(key)) {
      slidesRef.current.set(key, node);
      resize();
    }
  };

  const setCardHeight = () => {
    wrapRef.current?.style.setProperty(ADVERTISING_ROW_HEIGHT, "");

    const slideHeights = [...slidesRef.current].map(slideEl => {
      return slideEl[1].offsetHeight;
    });

    wrapRef.current?.style.setProperty(ADVERTISING_ROW_HEIGHT, `${Math.max(...slideHeights)}px`);
  };

  const resize = useStableFunction(() => {
    const slideEl = slidesRef.current.get(0);

    if (slideEl) {
      setCardHeight();
    }
  });

  // There is a timing issue; Sometimes
  // ADVERTISING_ROW_HEIGHT does not get set
  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [resize]);

  const { vehicleDetailPath } = usePathBuilder();

  const utmParams = {
    source: utmSource,
    campaign: UtmCampaignType.Banner,
  };

  const vehicleDetailPathBuilder = useStableFunction(({ id, slug }: { id: number; slug: string }) => {
    const detailPath = vehicleDetailPath({
      absolute: true,
      id,
      slug,
    });

    return setUtmParams(detailPath, utmParams);
  });

  const carCardContext: CarCardContextType = useMemo(
    () => ({
      linkBuilder: vehicleDetailPathBuilder,
      enableCompare: false,
      enableFavorites: false,
      linkTarget: "_blank",
    }),
    [vehicleDetailPathBuilder],
  );

  return (
    <WhitelabelBannerContainer
      carCardContext={carCardContext}
      containerRef={wrapRef}
      homeLink={setUtmParams(homePath({ absolute: true }), utmParams)}
      allVehiclesLink={setUtmParams(
        searchPath({
          absolute: true,
        }),
        utmParams,
      )}
    >
      <VisualHeroSliderContainer
        pauseAnimating={pauseAnimating}
        resumeAnimating={resumeAnimating}
        navigation={slides.map((slide, index) => (
          <VisualHeroSliderNav
            key={index}
            active={activeSlide == index}
            animating={isAnimating}
            displayDuration={slide.displayDuration}
            title={slide.title}
            onClick={() => navigateToSlide(index)}
          />
        ))}
      >
        {slides.map((slide, key) => {
          const active = activeSlide === key;
          return (
            <SlideContextProvider key={key} slide={slide}>
              <QueryParamProvider adapter={BannerAdapter}>
                <div className={classNames("slider__item", { active: active })}>
                  <BannerSlide ref={node => setSlideRef(key, node)} utmParams={utmParams} />
                </div>
              </QueryParamProvider>
            </SlideContextProvider>
          );
        })}
      </VisualHeroSliderContainer>
    </WhitelabelBannerContainer>
  );
};

// Remove ::after or add overflow: hidden
const Banner = () => {
  const whitelabelConfig = useBannerWhitelabelConfig();
  const { data, isFetching } = useGetBannerQuery(whitelabelConfig.id);
  const banner = data?.banner;

  if (isFetching) {
    return null;
  }

  if (!banner) {
    logger.error("Invalid Whitelabel Banner", { whitelabelConfig: whitelabelConfig, site: document.referrer });
    return null;
  }

  return <BannerInner slides={banner.slides} utmSource={banner.utmSource} />;
};

export default Banner;

import React from "react";
import i18next from "i18next";
import Button from "../../visual-components/components/Button";
import { useZeroResultsAlternativeVehicles } from "../../vehicle/hooks/useZeroResultsAlternativeVehicles";
import { notNil } from "../../common/helpers/isNil";
import useVehicleSearchData from "../../algolia/hooks/useVehicleSearchData";
import ZeroResultsVehicles from "./ZeroResultsVehicles";

type Props = {
  showSaveSearchOverlay?: () => void;
  resetFilters: () => void;
  postedQuery: string;
  algoliaOptions: ReturnType<typeof useVehicleSearchData>["algoliaOptions"];
};

const DefaultNoResults: React.FC<Props> = ({ showSaveSearchOverlay, postedQuery, resetFilters, algoliaOptions }) => {
  const foundVehicleAlternatives = useZeroResultsAlternativeVehicles(algoliaOptions);

  return (
    <>
      <div className="no-results-wrap">
        <div className="lead cms">{i18next.t("VEHICLESEARCH NO RESULTS EXPLANATION TEXT")}</div>
        <div className="btn-row">
          {showSaveSearchOverlay ? (
            <Button icon="bell" onClick={showSaveSearchOverlay}>
              {i18next.t("SAVE SEARCH")}
            </Button>
          ) : null}
        </div>
      </div>
      {notNil(foundVehicleAlternatives) ? (
        <ZeroResultsVehicles
          postedQuery={postedQuery}
          resetFilters={resetFilters}
          vehicles={foundVehicleAlternatives}
        />
      ) : null}
    </>
  );
};

export default DefaultNoResults;

const getTimer = (callback: () => void, delay: number) => {
  let timeRemaining: number = delay;

  let timeout: NodeJS.Timeout | null = null;
  let start: number = 0;

  const resume = () => {
    if (!timeout) {
      start = performance.now();
      timeout = setTimeout(callback, timeRemaining);
    }
  };

  const pause = () => {
    if (timeout) {
      stop();
      timeRemaining -= performance.now() - start;
    }
  };

  const stop = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  resume();

  return {
    resume,
    pause,
    stop,
  };
};

export default getTimer;

import { useEffect, useRef, useState } from "react";
import { Image, Link } from "../../hygraph/vo";
import useEvent from "../../common/hooks/useEvent";
import getTimer from "../../common/helpers/getTimer";

export type HeroSlideType = {
  displayDuration: number;
  title?: string | null;
  ctaLabel?: string | null;
  ctaLink?: Link | null;
  imageDesktop?: Image | null;
  imageMobile?: Image | null;
  sponsored?: boolean;
};

export const useHeroSlider = (heroSlides: HeroSlideType[]) => {
  const amountOfSlides = heroSlides.length;

  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const timeout = useRef<ReturnType<typeof getTimer> | null>(null);

  const queueNextSlide = (slide: number = 0) => {
    const timeToNextSlide = heroSlides[slide].displayDuration;
    const nextSlide = (slide + 1) % amountOfSlides;

    timeout.current = getTimer(() => {
      setActiveSlide(nextSlide);
      queueNextSlide(nextSlide);
    }, timeToNextSlide);
  };

  const navigateToSlide = (index: number) => {
    timeout.current?.stop();
    timeout.current = null;
    setIsAnimating(false);
    setActiveSlide(index);
  };

  const pauseAnimating = () => {
    timeout.current?.pause();
  };

  const resumeAnimating = () => {
    timeout.current?.resume();
  };

  const startAnimating = useEvent(() => {
    if (!timeout.current && amountOfSlides > 1) {
      queueNextSlide();
    }
  });

  useEffect(() => startAnimating(), [startAnimating]);

  return {
    activeSlide,
    isAnimating,
    navigateToSlide,
    pauseAnimating,
    resumeAnimating,
  };
};

import { useGetVehiclesQuery } from "../../algolia/services/vehicleSearchApi";
import useVehicleSearchData from "../../algolia/hooks/useVehicleSearchData";

export const useZeroResultsAlternativeVehicles = (
  algoliaOptions: ReturnType<typeof useVehicleSearchData>["algoliaOptions"],
) => {
  const { data: zeroResultsVehicles } = useGetVehiclesQuery({
    query: "",
    options: {
      ...algoliaOptions,
    },
  });

  if (!zeroResultsVehicles || zeroResultsVehicles.nbHits === 0) {
    return null;
  }

  return zeroResultsVehicles.hits;
};

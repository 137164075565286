import React, { ComponentProps } from "react";
import useKeyboardSelect from "../../hooks/useKeyboardSelect";
import SelectField from "./SelectField";
import CustomSelectOptionCheckbox from "./CustomSelectOptionCheckbox";
import { SelectItem } from "./SingleOptionSelect";

type Props = {
  values: SelectItem[];
  options: SelectItem[];
  onChange: (value: string, checked: boolean) => void;
} & Omit<ComponentProps<typeof SelectField>, "value" | "children">;

const MultiOptionSelect: React.FC<Props> = ({ values, options, search, onChange, ...rest }) => {
  const appliedTagMap = values.reduce<Record<string, boolean>>((agg, item) => {
    if (item) {
      const value = item.value ?? item.name;
      agg[value] = true;
    }
    return agg;
  }, {});

  const { onKeyDown, keyboardNavigatedItem, updateMouseHoverState } = useKeyboardSelect(
    options,
    item => {
      const value = item.value ?? item.name;
      const isApplied = appliedTagMap[value];
      onChange(value, !isApplied);
    },
    false,
  );

  const isVisible = (item: SelectItem): boolean => {
    const value = item.value ?? item.name;
    const isApplied = appliedTagMap[value];
    return isApplied || !item.invisible;
  };

  return (
    <SelectField
      search={search ? { ...search, onKeyDown } : undefined}
      value={values.map(({ name }) => name).join(", ")}
      onClose={() => {
        updateMouseHoverState(null);
      }}
      {...rest}
    >
      {options
        .filter(item => isVisible(item))
        .map(item => {
          const { value, name, inactive } = item;
          const isHovered = keyboardNavigatedItem === item;
          return (
            <CustomSelectOptionCheckbox
              key={name + value}
              checked={!!appliedTagMap[value ?? name]}
              hovered={isHovered}
              inactive={inactive}
              label={name}
              value={value}
              onChange={onChange}
              onMouseEnter={() => {
                updateMouseHoverState(item);
              }}
              onMouseLeave={() => {
                updateMouseHoverState(null);
              }}
            />
          );
        })}
    </SelectField>
  );
};

export default MultiOptionSelect;

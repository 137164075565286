import React, { useEffect, useId, useState } from "react";
import i18next from "i18next";
import Popup from "../../visual-components/components/Popup";
import PopupContent from "../../visual-components/components/PopupContent";
import DialogPortal from "../components/DialogPortal";
import Button from "../../visual-components/components/Button";
import Label from "../../visual-components/components/Label";

function useShare({ title, text, url }: { title?: string; text: string; url: string }) {
  const [nativeShareEnabled, setNativeShareEnabled] = useState(false);

  // browser support for the Web Share API is currently limited
  // this feature check prevents showing the share button if the feature is not available
  useEffect(() => {
    if (!!navigator.share) {
      setNativeShareEnabled(true);
    }
  }, []);

  const share = async (title: string | undefined, text: string, url: string) => {
    try {
      await navigator.share({
        title,
        text,
        url,
      });
    } catch {
      // ignore error as AbortError is likely due to the user closing the dialog
    }
  };

  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const id = useId();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        setCopiedToClipboard(true);
      },
      e => {
        // todo: consider adding error handling if this case occurs frequently
        throw e;
      },
    );
  };

  return {
    openShare: () => {
      if (nativeShareEnabled) {
        share(title, text, url);
      } else {
        setCopiedToClipboard(false);
        setShowCopyDialog(true);
      }
    },
    shareOverlay: (
      <DialogPortal>
        <Popup
          keyboardDismissable
          open={showCopyDialog}
          close={() => {
            setShowCopyDialog(false);
          }}
        >
          <PopupContent
            body={<p>{i18next.t("SHARE OVERLAY INFO TEXT")}</p>}
            headerIcon={{ icon: "check" }}
            success={copiedToClipboard}
            title={copiedToClipboard ? i18next.t("COPIED LINK") : i18next.t("COPY LINK")}
            form={
              <>
                <div className="form__row">
                  <div className="form__col">
                    <Label htmlFor={id} label={i18next.t("SHARE LINK TO COPY")} />
                    <input
                      readOnly
                      className="input input--search"
                      id={id}
                      type="text"
                      value={url}
                      onFocus={e => {
                        e.target.select();
                      }}
                    />
                  </div>
                </div>
                <div className="form__row right">
                  <div className="form__col">
                    <Button onClick={copyToClipboard}>{i18next.t("COPY")}</Button>
                  </div>
                </div>
              </>
            }
          />
        </Popup>
      </DialogPortal>
    ),
  };
}

export default useShare;

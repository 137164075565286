import { TFunction } from "i18next";
import { GeoLocation } from "../../../modules/geo/components/GeoPopup";
import {
  BODY_TYPE_QUERY_PARAM,
  EXTERIOR_COLOR_QUERY_PARAM,
  FIRST_REGISTRATION_QUERY_PARAM,
  FUEL_TYPE_QUERY_PARAM,
  GEO_QUERY_PARAM,
  MILEAGE_QUERY_PARAM,
  OPTIONS_QUERY_PARAM,
  PRICE_QUERY_PARAM,
  SEAT_NUM_QUERY_PARAM,
  TRANSMISSION_QUERY_PARAM,
  VEHICLE_CONDITION_INSIDE_IDS,
  VEHICLE_CONDITION_QUERY_PARAM,
} from "../../../modules/vehicle-search/services/filterDefinition";

export type QuestionId =
  | "BODY TYPE"
  | "SEAT NUM"
  | "FUEL TYPE"
  | "VEHICLE CONDITION"
  | "PRICE"
  | "DEALER"
  | "COLOR"
  | "COMFORT"
  | "SECURITY";

export const QUESTION_DISPLAY_ORDER: QuestionId[] = [
  "BODY TYPE",
  "SEAT NUM",
  "FUEL TYPE",
  "VEHICLE CONDITION",
  "PRICE",
  "DEALER",
  "COLOR",
  "COMFORT",
  "SECURITY",
];

export const SUMMARY_STEP = QUESTION_DISPLAY_ORDER.length;

export const STEP_ID_PREFIX = "STEP";

export type AnswerType = "single" | "multi" | "slider";
export type QuestionTypes = "common" | "price" | "dealer" | "color" | "interactive";

type FilterKeys = [
  typeof BODY_TYPE_QUERY_PARAM,
  typeof SEAT_NUM_QUERY_PARAM,
  typeof FUEL_TYPE_QUERY_PARAM,
  typeof VEHICLE_CONDITION_QUERY_PARAM,
  typeof FIRST_REGISTRATION_QUERY_PARAM,
  typeof MILEAGE_QUERY_PARAM,
  typeof PRICE_QUERY_PARAM,
  typeof GEO_QUERY_PARAM,
  typeof EXTERIOR_COLOR_QUERY_PARAM,
  typeof OPTIONS_QUERY_PARAM,
  typeof TRANSMISSION_QUERY_PARAM,
];

type FilterKey = FilterKeys[number];

export type Filters = {
  filterKey: FilterKey;
  filterValues: string[] | null;
};

export type ConditionId = "NEW" | "KINDA" | "USED" | "OPEN";
export type PriceId = "b" | "l";
export type DealerId = "VERY IMPORTANT" | "SOMEHOW IMPORTANT" | "INDIFFERENT";

export type OptionBase = {
  id: string;
  filters: Filters[];
};

export type OptionCommon = OptionBase & {
  lead: (t: TFunction) => string;
  label: (t: TFunction) => string;
};

type OptionDealer = OptionBase & {
  id: DealerId;
  lead: (t: TFunction) => string;
  label: (t: TFunction) => string;
  withLocation: boolean;
};

type OptionPrice = OptionBase & {
  id: PriceId;
  label: (price: string, t: TFunction) => string;
};

export type OptionColor = OptionBase & {
  label: (t: TFunction) => string;
};

export type OptionInteractive = OptionBase & {
  label: (t: TFunction) => string;
};

export type OptionTypes = OptionCommon | OptionPrice | OptionDealer | OptionColor | OptionInteractive;

type QuestionItemBase = {
  answerPrefix: (t: TFunction) => string;
  answerColon: boolean;
  answerType: AnswerType;
  question: (t: TFunction) => string;
  questionType: QuestionTypes;
  summary: (t: TFunction) => string;
};

type QuestionItemCommon = QuestionItemBase & {
  questionType: "common";
  options: OptionCommon[];
};

type QuestionItemPrice = QuestionItemBase & {
  questionType: "price";
  options: OptionPrice[];
};

type QuestionItemDealer = QuestionItemBase & {
  questionType: "dealer";
  options: OptionDealer[];
};

type QuestionItemColor = QuestionItemBase & {
  questionType: "color";
  options: OptionColor[];
};

type QuestionItemInteractive = QuestionItemBase & {
  questionType: "interactive";
  options: OptionInteractive[];
};

export type QuestionItems =
  | QuestionItemCommon
  | QuestionItemPrice
  | QuestionItemDealer
  | QuestionItemColor
  | QuestionItemInteractive;

enum COLOR_TYPE {
  BLUE = "1",
  GREEN = "2",
  RED = "3",
  BEIGE = "4",
  GREY = "5",
  YELLOW = "6",
  WHITE = "7",
  BLACK = "8",
  BROWN = "9",
  ORANGE = "10",
  VIOLET = "11",
  ANTRAZIT = "12",
  TURQUOISE = "13",
  GOLD = "14",
  SILVER = "15",
  ROSE = "16",
  BORDEAUX = "17",
  BRONZE = "20",
}

export const QUESTION_CONFIG: Record<QuestionId, QuestionItems> = {
  "BODY TYPE": {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I AM"),
    answerColon: false,
    answerType: "multi",
    question: t => t("MATCHMAKER QUESTION BODY TYPE"),
    questionType: "common",
    summary: t => t("MATCHMAKER SUMMARY BODY TYPE"),
    options: [
      {
        id: "OFF ROAD",
        label: t => t("MATCHMAKER OPTION BODY TYPE OFF ROAD"),
        lead: t => t("MATCHMAKER LEAD BODY TYPE OFF ROAD"),
        filters: [
          {
            filterKey: "bodyType",
            filterValues: ["g|7", "c|5"],
          },
        ],
      },
      {
        id: "OPEN AIR",
        label: t => t("MATCHMAKER OPTION BODY TYPE OPEN AIR"),
        lead: t => t("MATCHMAKER LEAD BODY TYPE OPEN AIR"),
        filters: [
          {
            filterKey: "bodyType",
            filterValues: ["g|1"],
          },
        ],
      },
      {
        id: "MULTI TASK",
        label: t => t("MATCHMAKER OPTION BODY TYPE MULTI TASK"),
        lead: t => t("MATCHMAKER LEAD BODY TYPE MULTI TASK"),
        filters: [
          {
            filterKey: "bodyType",
            filterValues: ["g|3", "g|5"],
          },
        ],
      },
      {
        id: "URBAN NOMAD",
        label: t => t("MATCHMAKER OPTION BODY TYPE URBAN NOMAD"),
        lead: t => t("MATCHMAKER LEAD BODY TYPE URBAN NOMAD"),
        filters: [
          {
            filterKey: "bodyType",
            filterValues: ["g|5"],
          },
        ],
      },
      {
        id: "SPORTY",
        label: t => t("MATCHMAKER OPTION BODY TYPE SPORTY"),
        lead: t => t("MATCHMAKER LEAD BODY TYPE SPORTY"),
        filters: [
          {
            filterKey: "bodyType",
            filterValues: ["g|2"],
          },
        ],
      },
      {
        id: "FAMILY PERSON",
        label: t => t("MATCHMAKER OPTION BODY TYPE FAMILY PERSON"),
        lead: t => t("MATCHMAKER LEAD BODY TYPE FAMILY PERSON"),
        filters: [
          {
            filterKey: "bodyType",
            filterValues: ["c|7", "c|18", "c|7038", "c|14"],
          },
        ],
      },
    ],
  },
  "SEAT NUM": {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I AM"),
    answerColon: false,
    answerType: "single",
    question: t => t("MATCHMAKER QUESTION SEAT NUM"),
    questionType: "common",
    summary: t => t("MATCHMAKER SUMMARY SEAT NUM"),
    options: [
      {
        id: "SINGLE",
        label: t => t("MATCHMAKER OPTION SEAT NUM SINGLE"),
        lead: t => t("MATCHMAKER LEAD SEAT NUM SINGLE"),
        filters: [
          {
            filterKey: "seats",
            filterValues: ["i-1-4"],
          },
        ],
      },
      {
        id: "FAVORITE PARENT",
        label: t => t("MATCHMAKER OPTION SEAT NUM FAVORITE PARENT"),
        lead: t => t("MATCHMAKER LEAD SEAT NUM FAVORITE PARENT"),
        filters: [
          {
            filterKey: "seats",
            filterValues: ["i-4-5"],
          },
        ],
      },
      {
        id: "FAMILY GUY",
        label: t => t("MATCHMAKER OPTION SEAT NUM FAMILY GUY"),
        lead: t => t("MATCHMAKER LEAD SEAT NUM FAMILY GUY"),
        filters: [
          {
            filterKey: "seats",
            filterValues: ["i-6-7"],
          },
        ],
      },
      {
        id: "TEAM SPORT",
        label: t => t("MATCHMAKER OPTION SEAT NUM TEAM SPORT"),
        lead: t => t("MATCHMAKER LEAD SEAT NUM TEAM SPORT"),
        filters: [
          {
            filterKey: "seats",
            filterValues: ["i-7--"],
          },
        ],
      },
    ],
  },
  "FUEL TYPE": {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I WANT"),
    answerColon: false,
    answerType: "multi",
    question: t => t("MATCHMAKER QUESTION FUEL TYPE"),
    questionType: "common",
    summary: t => t("MATCHMAKER SUMMARY FUEL TYPE"),
    options: [
      {
        id: "DIESEL",
        label: t => t("MATCHMAKER OPTION FUEL TYPE DIESEL"),
        lead: t => t("MATCHMAKER LEAD FUEL TYPE DIESEL"),
        filters: [
          {
            filterKey: "fuelType",
            filterValues: ["g|2"],
          },
        ],
      },
      {
        id: "PETROL",
        label: t => t("MATCHMAKER OPTION FUEL TYPE PETROL"),
        lead: t => t("MATCHMAKER LEAD FUEL TYPE PETROL"),
        filters: [
          {
            filterKey: "fuelType",
            filterValues: ["g|1"],
          },
        ],
      },
      {
        id: "HYBRID",
        label: t => t("MATCHMAKER OPTION FUEL TYPE HYBRID"),
        lead: t => t("MATCHMAKER LEAD FUEL TYPE HYBRID"),
        filters: [
          {
            filterKey: "fuelType",
            filterValues: ["g|3"],
          },
        ],
      },
      {
        id: "ELECTRIC",
        label: t => t("MATCHMAKER OPTION FUEL TYPE ELECTRIC"),
        lead: t => t("MATCHMAKER LEAD FUEL TYPE ELECTRIC"),
        filters: [
          {
            filterKey: "fuelType",
            filterValues: ["g|4"],
          },
        ],
      },
    ],
  },
  "VEHICLE CONDITION": {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I SAY"),
    answerColon: true,
    answerType: "single",
    question: t => t("MATCHMAKER QUESTION VEHICLE CONDITION"),
    questionType: "common",
    summary: t => t("MATCHMAKER SUMMARY VEHICLE CONDITION"),
    options: [
      {
        id: "NEW" satisfies ConditionId,
        label: t => t("MATCHMAKER OPTION VEHICLE CONDITION NEW"),
        lead: t => t("MATCHMAKER LEAD VEHICLE CONDITION NEW"),
        filters: [
          {
            filterKey: "vehicleCondition",
            filterValues: [VEHICLE_CONDITION_INSIDE_IDS.NEW],
          },
        ],
      },
      {
        id: "KINDA" satisfies ConditionId,
        label: t => t("MATCHMAKER OPTION VEHICLE CONDITION KINDA"),
        lead: t => t("MATCHMAKER LEAD VEHICLE CONDITION KINDA"),
        filters: [
          {
            filterKey: "firstRegistration",
            filterValues: ["i-%s-"],
          },
          {
            filterKey: "mileage",
            filterValues: ["i--10000"],
          },
        ],
      },
      {
        id: "USED" satisfies ConditionId,
        label: t => t("MATCHMAKER OPTION VEHICLE CONDITION USED"),
        lead: t => t("MATCHMAKER LEAD VEHICLE CONDITION USED"),
        filters: [
          {
            filterKey: "firstRegistration",
            filterValues: ["i-%s-"],
          },
          {
            filterKey: "mileage",
            filterValues: ["i-10000-60000"],
          },
        ],
      },
      {
        id: "OPEN" satisfies ConditionId,
        label: t => t("MATCHMAKER OPTION VEHICLE CONDITION OPEN"),
        lead: t => t("MATCHMAKER LEAD VEHICLE CONDITION OPEN"),
        filters: [
          {
            filterKey: "mileage",
            filterValues: ["i-60000--"],
          },
        ],
      },
    ],
  },
  PRICE: {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I THINK LIKE"),
    answerColon: false,
    answerType: "slider",
    question: t => t("MATCHMAKER QUESTION PRICE"),
    questionType: "price",
    summary: t => t("MATCHMAKER SUMMARY PRICE"),
    options: [
      {
        id: "b",
        label: (price, t) => t("UPTO PLACEHOLDER", { value: price }),
        filters: [
          {
            filterKey: "price",
            filterValues: ["b--%s"],
          },
        ],
      },
      {
        id: "l",
        label: (price, t) => t("UPTO PLACEHOLDER", { value: price }),
        filters: [
          {
            filterKey: "price",
            filterValues: ["l--%s"],
          },
        ],
      },
    ],
  },
  DEALER: {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I THINK"),
    answerColon: true,
    answerType: "single",
    question: t => t("MATCHMAKER QUESTION DEALER"),
    questionType: "dealer",
    summary: t => t("MATCHMAKER SUMMARY DEALER"),
    options: [
      {
        id: "VERY IMPORTANT",
        lead: t => t("MATCHMAKER LEAD DEALER VERY IMPORTANT"),
        label: t => t("MATCHMAKER OPTION DEALER VERY IMPORTANT"),
        withLocation: true,
        filters: [
          {
            filterKey: "geo",
            filterValues: ["%s|%s|20|%s"],
          },
        ],
      },
      {
        id: "SOMEHOW IMPORTANT",
        lead: t => t("MATCHMAKER LEAD DEALER SOMEHOW IMPORTANT"),
        label: t => t("MATCHMAKER OPTION DEALER SOMEHOW IMPORTANT"),
        withLocation: true,
        filters: [
          {
            filterKey: "geo",
            filterValues: ["%s|%s|50|%s"],
          },
        ],
      },
      {
        id: "INDIFFERENT",
        lead: t => t("MATCHMAKER LEAD DEALER INDIFFERENT"),
        label: t => t("MATCHMAKER OPTION DEALER INDIFFERENT"),
        withLocation: false,
        filters: [
          {
            filterKey: "geo",
            filterValues: null,
          },
        ],
      },
    ],
  },
  COLOR: {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX I LOVE IT"),
    answerColon: false,
    answerType: "multi",
    question: t => t("MATCHMAKER QUESTION COLOR"),
    questionType: "color",
    summary: t => t("MATCHMAKER SUMMARY COLOR"),
    options: [
      {
        id: "FIRE",
        label: t => t("MATCHMAKER OPTION COLOR FIRE"),
        filters: [
          {
            filterKey: "exteriorColor",
            filterValues: [
              COLOR_TYPE.ROSE,
              COLOR_TYPE.ORANGE,
              COLOR_TYPE.BRONZE,
              COLOR_TYPE.GOLD,
              COLOR_TYPE.YELLOW,
              COLOR_TYPE.VIOLET,
              COLOR_TYPE.RED,
              COLOR_TYPE.BORDEAUX,
            ],
          },
        ],
      },
      {
        id: "FRESH",
        label: t => t("MATCHMAKER OPTION COLOR FRESH"),
        filters: [
          {
            filterKey: "exteriorColor",
            filterValues: [COLOR_TYPE.GREEN, COLOR_TYPE.TURQUOISE, COLOR_TYPE.YELLOW],
          },
        ],
      },
      {
        id: "COOL",
        label: t => t("MATCHMAKER OPTION COLOR COOL"),
        filters: [
          {
            filterKey: "exteriorColor",
            filterValues: [COLOR_TYPE.TURQUOISE, COLOR_TYPE.VIOLET, COLOR_TYPE.BLUE],
          },
        ],
      },
      {
        id: "DISCREET",
        label: t => t("MATCHMAKER OPTION COLOR DISCREET"),
        filters: [
          {
            filterKey: "exteriorColor",
            filterValues: [
              COLOR_TYPE.ANTRAZIT,
              COLOR_TYPE.BEIGE,
              COLOR_TYPE.BRONZE,
              COLOR_TYPE.BROWN,
              COLOR_TYPE.SILVER,
              COLOR_TYPE.BLACK,
              COLOR_TYPE.GREY,
            ],
          },
        ],
      },
      {
        id: "CLEAN",
        label: t => t("MATCHMAKER OPTION COLOR CLEAN"),
        filters: [
          {
            filterKey: "exteriorColor",
            filterValues: [COLOR_TYPE.SILVER, COLOR_TYPE.WHITE],
          },
        ],
      },
    ],
  },
  COMFORT: {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX FOR SURE"),
    answerColon: true,
    answerType: "multi",
    question: t => t("MATCHMAKER QUESTION COMFORT"),
    questionType: "interactive",
    summary: t => t("MATCHMAKER SUMMARY COMFORT"),
    options: [
      {
        id: "COUPLING DEVICE",
        label: t => t("MATCHMAKER OPTION COMFORT COUPLING DEVICE"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["76"],
          },
        ],
      },
      {
        id: "CRUISE CONTROL",
        label: t => t("MATCHMAKER OPTION COMFORT CRUISE CONTROL"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["20"],
          },
        ],
      },
      {
        id: "AIR CONDITIONING",
        label: t => t("MATCHMAKER OPTION COMFORT AIR CONDITIONING"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["2"],
          },
        ],
      },
      {
        id: "SEAT HEATING",
        label: t => t("MATCHMAKER OPTION COMFORT SEAT HEATING"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["3"],
          },
        ],
      },
      {
        id: "NAVIGATION SYSTEM",
        label: t => t("MATCHMAKER OPTION COMFORT NAVIGATION SYSTEM"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["4"],
          },
        ],
      },
      {
        id: "PANORAMA ROOF",
        label: t => t("MATCHMAKER OPTION COMFORT PANORAMA ROOF"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["6"],
          },
        ],
      },
      {
        id: "AUTOMATIC",
        label: t => t("MATCHMAKER OPTION COMFORT AUTOMATIC"),
        filters: [
          {
            filterKey: "transmission",
            filterValues: ["2"],
          },
        ],
      },
    ],
  },
  SECURITY: {
    answerPrefix: t => t("MATCHMAKER ANSWER PREFIX FOR SURE"),
    answerColon: true,
    answerType: "multi",
    question: t => t("MATCHMAKER QUESTION SECURITY"),
    questionType: "interactive",
    summary: t => t("MATCHMAKER SUMMARY SECURITY"),
    options: [
      {
        id: "4X4",
        label: t => t("MATCHMAKER OPTION SECURITY 4X4"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["85"],
          },
        ],
      },
      {
        id: "ISOFIX",
        label: t => t("MATCHMAKER OPTION SECURITY ISOFIX"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["39"],
          },
        ],
      },
      {
        id: "LANE KEEPING ASSIST",
        label: t => t("MATCHMAKER OPTION SECURITY LANE KEEPING ASSIST"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["42"],
          },
        ],
      },
      {
        id: "SIDE AIRBAGS",
        label: t => t("MATCHMAKER OPTION SECURITY SIDE AIRBAGS"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["67"],
          },
        ],
      },
      {
        id: "BLIND SPOT ASSISTANT",
        label: t => t("MATCHMAKER OPTION SECURITY BLIND SPOT ASSISTANT"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["15"],
          },
        ],
      },
      {
        id: "360 CAMERA",
        label: t => t("MATCHMAKER OPTION SECURITY 360 CAMERA"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["1"],
          },
        ],
      },
      {
        id: "BENDING LIGHT",
        label: t => t("MATCHMAKER OPTION SECURITY BENDING LIGHT"),
        filters: [
          {
            filterKey: "options",
            filterValues: ["19"],
          },
        ],
      },
    ],
  },
};

// single, multi -> number[]
// slider -> string
// no choice (indifferent) -> null
export type Answer = string[] | string | null;
export type Answers = Record<QuestionId, Answer>;
export type Matchmaker = {
  answers: Partial<Answers>;
  location: GeoLocation | null;
  rememberLocation: boolean;
};

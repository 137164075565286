import React, { useEffect, useMemo, useReducer, useRef } from "react";
import i18next from "i18next";
import { ALL_FILTERS, BASE_FILTERS, FILTER_DEFINITIONS } from "..//services/filterDefinition";
import { getActiveAppliedFilterItems } from "..//helpers/getActiveAppliedFilterItems";
import { AggregatedFilterItem, FilterReferenceData } from "..//services/reference-data-aggregator/types";
import { VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import { getOccurrenceFor } from "..//services/getOccurrenceFor";
import { logger } from "../../common/scripts/logger";
import RangeFilter from "./filters/RangeFilter";
import BrandModelFilter from "./filters/BrandModelFilter";
import TagFilter from "./filters/TagFilter";
import GroupSelectFilter from "./filters/GroupSelectFilter";
import MultiSelectFilter from "./filters/MultiSelectFilter";
import GeoFilter from "./filters/GeoFilter";
import { useOverlayContext } from "./OverlayContext";
import ModelFilter from "./filters/ModelFilter";

type Props = {
  view: "base" | "powerSearch";
  filterData: FilterReferenceData;
  appliedFilters?: Record<string, any>;
  searchData: VehicleSearchResponse | undefined;
};

type FilterRenderConfiguration = {
  sidebarFilters?: string[];
  excludedFilters: string[];
};

// If a brand is "preselected", the brand dropdown is hidden.
// A single brand must be selected through brandRestrictions in
// useVehicleSearchData for the correct models to be shown if no models selected.
export const PreselectedBrandFilterRenderContext = React.createContext<number | null>(null);

const FilterRenderContext = React.createContext<FilterRenderConfiguration | null>(null);

export function FilterRenderConfigurationProvider({
  sidebarFilters,
  excludedFilters,
  children,
}: {
  sidebarFilters?: string[];
  excludedFilters: string[];
  children: React.ReactNode;
}) {
  const state: FilterRenderConfiguration = useMemo(
    () => ({
      sidebarFilters,
      excludedFilters,
    }),
    [sidebarFilters, excludedFilters],
  );
  return <FilterRenderContext.Provider value={state}>{children}</FilterRenderContext.Provider>;
}

/**
 * filters, which are rendered in base filters should stay there, even if they're removed until the overlay opens again
 * @param appliedFilterItems
 */
function useBaseViewFilters(appliedFilterItems: string[]): string[] {
  const sidebarFilters = React.useContext(FilterRenderContext)?.sidebarFilters ?? BASE_FILTERS;
  const { isOpen } = useOverlayContext();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  // prefer ref to prevent unnecessary re-renders
  const previouslyAppliedFilterItems = useRef<Set<string>>(new Set());

  useEffect(() => {
    const previouslyAppliedSet = previouslyAppliedFilterItems.current;
    previouslyAppliedFilterItems.current = new Set([...Array.from(previouslyAppliedSet), ...appliedFilterItems]);
  }, [appliedFilterItems]);

  useEffect(() => {
    previouslyAppliedFilterItems.current = new Set();
    forceUpdate();
  }, [isOpen]);
  return Array.from(
    new Set([...sidebarFilters, ...Array.from(previouslyAppliedFilterItems.current), ...appliedFilterItems]),
  );
}

function useFilters(view: "base" | "powerSearch", appliedFilterItems: string[]): string[] {
  const excludedFilters = React.useContext(FilterRenderContext)?.excludedFilters ?? [];
  const baseFilters = useBaseViewFilters(appliedFilterItems);

  const filters = view === "base" ? baseFilters : ALL_FILTERS;

  const excludedFilterMap = Object.fromEntries(excludedFilters.map(filter => [filter, true]));

  return filters.filter(filter => !excludedFilterMap[filter]);
}

const FilterRenderer: React.FC<Props> = ({ view, filterData, appliedFilters = {}, searchData }) => {
  const appliedFilterItems = getActiveAppliedFilterItems(appliedFilters).map(([queryParam]) => queryParam);
  const filters = useFilters(view, appliedFilterItems);

  const isPowerSearch = view === "powerSearch";

  const preselectedBrandFilter = React.useContext(PreselectedBrandFilterRenderContext);

  return (
    <>
      {filters
        .map(queryParam => FILTER_DEFINITIONS[queryParam])
        .map(filter => {
          switch (filter.type) {
            case "range":
              return (
                <RangeFilter
                  key={filter.queryParam}
                  data={filterData}
                  queryParam={filter.queryParam}
                  title={filter.title(i18next.t)}
                  units={filter.units}
                />
              );
            case "brandModel":
              return preselectedBrandFilter ? (
                <ModelFilter
                  key={filter.queryParam}
                  brandId={preselectedBrandFilter}
                  data={filterData}
                  queryParam={filter.queryParam}
                  searchData={searchData}
                />
              ) : (
                <BrandModelFilter
                  key={filter.queryParam}
                  data={filterData}
                  queryParam={filter.queryParam}
                  searchData={searchData}
                />
              );
            case "tag":
              // If not explicitly declaring type of list a type error is being yielded
              const tagList: AggregatedFilterItem[] = filterData[filter.dataKey].list;
              return (
                <TagFilter
                  key={filter.queryParam}
                  queryParam={filter.queryParam}
                  renderOnlyBasicItems={!isPowerSearch}
                  title={filter.title(i18next.t)}
                  data={tagList.map(({ name, ...rest }) => ({
                    name,
                    occurrence: getOccurrenceFor(filter.facetKey, name, searchData),
                    ...rest,
                  }))}
                />
              );
            case "groupSelect":
              return (
                <GroupSelectFilter
                  key={filter.queryParam}
                  fixOpen={isPowerSearch}
                  queryParam={filter.queryParam}
                  renderOnlyGroups={!isPowerSearch}
                  title={filter.title(i18next.t)}
                  data={filterData[filter.dataKey].list.map(({ children, ...rest }) => ({
                    ...rest,
                    children: children.map(({ name, ...rest }) => ({
                      name,
                      occurrence: getOccurrenceFor(filter.childFacetKey, name, searchData),
                      ...rest,
                    })),
                  }))}
                />
              );
            case "multiSelect":
              // If not explicitly declaring type of list a type error is being yielded
              const multiSelectList: AggregatedFilterItem[] = filterData[filter.dataKey].list;
              return (
                <MultiSelectFilter
                  key={filter.queryParam}
                  conjunctive={filter.conjunctive}
                  fixOpen={isPowerSearch}
                  queryParam={filter.queryParam}
                  // todo: this logic should be reused in the other filters
                  title={filter.title(i18next.t)}
                  data={multiSelectList.map(({ name, value, ...rest }) => ({
                    name,
                    value,
                    occurrence: getOccurrenceFor(filter.facetKey, filter.getSearchValue?.(value) ?? name, searchData),
                    ...rest,
                  }))}
                />
              );
            case "geo":
              return <GeoFilter key={filter.queryParam} queryParam={filter.queryParam} />;
            default:
              logger.warn(`unknown filter type ${(filter as any).type}`);
              return null;
          }
        })}
    </>
  );
};

export default FilterRenderer;

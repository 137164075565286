import { VEHICLE_FACETS } from "../../algolia/services/vehicleFacetKeys";
import { FACET_KEY_FILTER_MAP } from "../../vehicle-search/services/facetKeyFilterMap";
import {
  TagType,
  buildGroupAppliedTag,
  mapAppliedTagToQueryParam,
} from "../../vehicle-search/services/filter-types/groupSelectFilterTypeHelpers";
import {
  FilterItemReferenceData,
  FilterReferenceData,
  GroupReferenceData,
} from "../../vehicle-search/services/reference-data-aggregator/types";
import {
  MODEL_BRAND_SEPARATOR,
  buildBrandModelFacetFilter,
} from "../../vehicle-search/services/filter-types/brandModelFilterTypeHelpers";
import { getPathBuilders } from "../../../router/helpers/pathBuilderHelpers";
import { logger } from "../scripts/logger";
import { notNil } from "./isNil";
import { sortedUrlParams } from "./sortedUrlParams";

const MAX_SUGGESTIONS_PER_FACET = 5;
const MIN_FACET_VALUE_RESULTS_FOR_SUGGESTION = 8;

const FACETS_FOR_SUGGESTIONS = [
  VEHICLE_FACETS.VEHICLE_CONDITION,
  VEHICLE_FACETS.BODY_TYPE_GROUP,
  VEHICLE_FACETS.TRANSMISSION_GROUP,
  VEHICLE_FACETS.FUEL_TYPE_GROUP,
] as const;

const SUGGESTIONS_ORDER: Record<string, number> = {
  brandModelFacetSuggestions: 1,
  modelSuggestions: 2,
  brandSuggestion: 3,
  facetSuggestions: 4,
} as const;

const FACET_FILTER_MAPPING: Partial<Record<FACET_MAPPED_TO_FILTER, keyof FilterReferenceData>> = {
  [VEHICLE_FACETS.BRAND]: "brands",
  [VEHICLE_FACETS.VEHICLE_CONDITION]: "vehicleConditions",
  [VEHICLE_FACETS.BODY_TYPE_GROUP]: "bodyTypes",
  [VEHICLE_FACETS.TRANSMISSION_GROUP]: "transmissions",
  [VEHICLE_FACETS.FUEL_TYPE_GROUP]: "fuelTypes",
};

export type Suggestion = {
  label: string;
  link: string;
};

type FACET_FOR_SUGGESTIONS = (typeof FACETS_FOR_SUGGESTIONS)[number];
type FACET_MAPPED_TO_FILTER = FACET_FOR_SUGGESTIONS | VEHICLE_FACETS.BRAND;

type SuggestionType = keyof typeof SUGGESTIONS_ORDER;

type GetPopularSearchesOptions = {
  brandRestriction?: string;
  modelRestriction?: string;
};

type SuggestionGetterParameters = {
  brand: string | null;
  model: string | null;
  facets: Record<string, Record<string, number>>;
  filterData: FilterReferenceData;
  baseUrl: URL;
  baseUrlWithoutBrandModel: URL;
};

class AccessValueThroughMapByNameError extends Error {}

const getBaseUrl = (pathBuilders: ReturnType<typeof getPathBuilders>, brandId?: string, modelId?: string) => {
  const path = pathBuilders.searchPath();
  const url = new URL(path, window.location.origin);

  // Prepopulate the brand and model if set
  return brandId
    ? buildUrl(url, VEHICLE_FACETS.BRAND, modelId ? buildBrandModelFacetFilter(brandId, modelId) : brandId)
    : url;
};

const buildUrl = (baseUrl: URL, facet: VEHICLE_FACETS, value: string) => {
  const url = new URL(baseUrl);
  const filter = FACET_KEY_FILTER_MAP[facet];
  const queryParam = filter.queryParam;

  if (url.searchParams.has(queryParam)) {
    url.searchParams.delete(queryParam);
  }

  switch (filter.type) {
    case "brandModel":
    case "tag":
      url.searchParams.append(queryParam, value);
      break;
    case "groupSelect":
      url.searchParams.append(queryParam, mapAppliedTagToQueryParam({ type: TagType.Group, value: value }));
      break;
    default:
      throw new Error(`Unsupported filter type: ${filter.type}`);
  }
  url.search = sortedUrlParams(url.search);
  return url;
};

const getFilterDefinitionByFacet = (facet: FACET_MAPPED_TO_FILTER, filterData: FilterReferenceData) =>
  filterData[FACET_FILTER_MAPPING[facet] as keyof FilterReferenceData] as FilterItemReferenceData;

// We need a helper function for this, because the variable access
// may fail if facets are refreshed before filterData after languageChange
const accessValueThroughMapByName = (object: FilterItemReferenceData | GroupReferenceData, key: string) => {
  try {
    return object.mapByName[key].value;
  } catch {
    throw new AccessValueThroughMapByNameError();
  }
};

const getFacetValueId = (facet: FACET_MAPPED_TO_FILTER, value: string, filterData: FilterReferenceData) =>
  accessValueThroughMapByName(getFilterDefinitionByFacet(facet, filterData), value);

const getSuggestedFacetValues = (facetValues: Record<string, number>) =>
  Object.keys(facetValues)
    .filter(facetValue => facetValues[facetValue] >= MIN_FACET_VALUE_RESULTS_FOR_SUGGESTION)
    .sort((a, b) => facetValues[b] - facetValues[a])
    .slice(0, MAX_SUGGESTIONS_PER_FACET);

const getSuggestionLabel = ({
  brand,
  model,
  suggestion,
}: {
  brand?: string | null;
  model?: string | null;
  suggestion?: string | null;
}) => [brand, model, suggestion].filter(notNil).join(" ");

const hasAppliedFilters = (appliedFilters: Record<string, string[] | string>, excludedFacets: string[]) =>
  Object.keys(appliedFilters)
    .filter(key => !excludedFacets.includes(key))
    .flatMap(key => appliedFilters[key])
    .filter(notNil).length > 0;

const flattenSuggestionResults = (results: Record<SuggestionType, Suggestion[]>) =>
  Object.keys(SUGGESTIONS_ORDER)
    .reduce((accumulator, suggestionType) => {
      accumulator[SUGGESTIONS_ORDER[suggestionType]] = results[suggestionType];
      return accumulator;
    }, [] as Suggestion[][])
    .flat();

const getModelSuggestions = (
  models: Record<string, number>,
  showSingleModel: boolean,
  singleFacetSuggestion: Suggestion | null,
  { brand, model, filterData, baseUrl }: SuggestionGetterParameters,
) => {
  const results: Suggestion[] = [];

  if (brand) {
    const brandId = getFacetValueId(VEHICLE_FACETS.BRAND, brand, filterData);

    if (!model) {
      const suggestedModels = getSuggestedFacetValues(models);

      for (const suggestedModel of suggestedModels) {
        const modelId = accessValueThroughMapByName(filterData.models[brandId], suggestedModel);
        const brandModel = buildBrandModelFacetFilter(brandId, modelId);

        const singleFacetSuggestionName = singleFacetSuggestion ? { suggestion: singleFacetSuggestion.label } : {};
        const modelSuggestionUrl = singleFacetSuggestion ? new URL(singleFacetSuggestion.link) : baseUrl;

        results.push({
          label: getSuggestionLabel({ brand: brand, model: suggestedModel, ...singleFacetSuggestionName }),
          link: buildUrl(modelSuggestionUrl, VEHICLE_FACETS.MODEL, brandModel).href,
        });
      }
    } else if (showSingleModel) {
      results.push({
        label: getSuggestionLabel({ brand: brand, model: model }),
        link: buildUrl(
          baseUrl,
          VEHICLE_FACETS.MODEL,
          buildBrandModelFacetFilter(brandId, accessValueThroughMapByName(filterData.models[brandId], model)),
        ).href,
      });
    }
  }

  return results;
};

const getFacetSuggestions = (
  appliedFilters: Record<string, string[] | string>,
  { baseUrl, baseUrlWithoutBrandModel, filterData, facets, brand, model }: SuggestionGetterParameters,
) => {
  const results: Record<SuggestionType, Suggestion[]> = {
    brandModelFacetSuggestions: [],
    facetSuggestions: [],
  };

  for (const facetForSuggestions of FACETS_FOR_SUGGESTIONS) {
    const facetFilterDefinition = FACET_KEY_FILTER_MAP[facetForSuggestions];
    const appliedFiltersForFacet = facetFilterDefinition ? appliedFilters[facetFilterDefinition.queryParam] : null;

    if (Array.isArray(appliedFiltersForFacet) && appliedFiltersForFacet.length === 1) {
      if (!brand && !model) {
        // If no brand or model, there is no value in
        // displaying an already applied filter on its own
        continue;
      }

      const appliedFilterId =
        facetFilterDefinition.type === "groupSelect"
          ? buildGroupAppliedTag(appliedFiltersForFacet[0]).value
          : appliedFiltersForFacet[0];

      results.facetSuggestions.push({
        label: getSuggestionLabel({
          suggestion: getFilterDefinitionByFacet(facetForSuggestions, filterData).map[appliedFilterId]?.name,
        }),
        link: buildUrl(baseUrlWithoutBrandModel, facetForSuggestions, appliedFilterId).href,
      });
    } else {
      const possibleFacetValues = facets[facetForSuggestions];
      if (!possibleFacetValues) {
        continue;
      }

      const suggestedFacetValues = getSuggestedFacetValues(possibleFacetValues);
      const searchSuggestions = suggestedFacetValues.map(suggestedValue => ({
        label: getSuggestionLabel({ brand: brand, model: model, suggestion: suggestedValue }),
        link: buildUrl(baseUrl, facetForSuggestions, getFacetValueId(facetForSuggestions, suggestedValue, filterData))
          .href,
      }));
      results.brandModelFacetSuggestions.push(...searchSuggestions);
    }
  }

  return results;
};

const getPopularSearchesFromFacets = (
  facets: Record<string, Record<string, number>>,
  filterData: FilterReferenceData,
  appliedFilters: Record<string, string[] | string>,
  pathBuilders: ReturnType<typeof getPathBuilders>,
  options: GetPopularSearchesOptions = {},
): Suggestion[] => {
  try {
    const results: Record<SuggestionType, Suggestion[]> = {
      brandSuggestion: [],
      modelSuggestions: [],
      brandModelSuggestion: [],
    };
    const models = facets[VEHICLE_FACETS.MODEL];

    const brandId = options.brandRestriction
      ? options.brandRestriction
      : appliedFilters[VEHICLE_FACETS.BRAND_MODEL]?.length === 1
        ? appliedFilters[VEHICLE_FACETS.BRAND_MODEL][0].split(MODEL_BRAND_SEPARATOR)[0]
        : undefined;

    const modelId = options.modelRestriction
      ? options.modelRestriction
      : appliedFilters[VEHICLE_FACETS.BRAND_MODEL]?.length === 1
        ? appliedFilters[VEHICLE_FACETS.BRAND_MODEL][0].split(MODEL_BRAND_SEPARATOR)[1]
        : undefined;

    const brand = brandId ? filterData.brands.map[brandId].name : null;
    const model = brandId && modelId ? filterData.models[brandId].map[modelId].name : null;

    const baseUrl = getBaseUrl(pathBuilders, brandId, modelId);
    const suggestionGetterParameters: SuggestionGetterParameters = {
      brand: brand,
      model: model,
      facets,
      filterData,
      baseUrl,
      baseUrlWithoutBrandModel: getBaseUrl(pathBuilders),
    };

    const facetSuggestionsResults = getFacetSuggestions(appliedFilters, suggestionGetterParameters);
    const singleFacetSuggestion =
      facetSuggestionsResults.facetSuggestions.length === 1 ? facetSuggestionsResults.facetSuggestions[0] : null;

    const hasAppliedFiltersExceptBrandModel = hasAppliedFilters(appliedFilters, [VEHICLE_FACETS.BRAND_MODEL]);

    if (!(options.brandRestriction || options.modelRestriction)) {
      results.modelSuggestions = getModelSuggestions(
        models,
        hasAppliedFiltersExceptBrandModel,
        singleFacetSuggestion,
        suggestionGetterParameters,
      );

      if (brandId && brand && (modelId || hasAppliedFiltersExceptBrandModel)) {
        results.brandSuggestion.push({
          label: getSuggestionLabel({ brand: brand }),
          link: buildUrl(baseUrl, VEHICLE_FACETS.BRAND, brandId).href,
        });
      }
    }

    return flattenSuggestionResults({ ...results, ...facetSuggestionsResults });
  } catch (error) {
    // Use a try catch to prevent faulty data from breaking the page
    // There is a bug to do with the language switcher:
    // If the new filterData for the language is not actually being fetched,
    // but a cached result is provided by redux, there seems to be a timing issue.
    // The function is provided with the old filterData, but new facets;
    // this makes our reverse lookups (mapByName[...]) fail.
    if (!(error instanceof AccessValueThroughMapByNameError)) {
      logger.error("Error in getPopularSearchesFromFacets", {
        data: { facets, filterData, appliedFilters, options },
        exception: error,
      });
    }

    return [];
  }
};

export default getPopularSearchesFromFacets;

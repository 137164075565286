import classNames from "classnames";
import React, { ReactNode } from "react";

const VisualHeroSliderContainer: React.FC<{
  pauseAnimating: () => void;
  resumeAnimating: () => void;
  children: ReactNode;
  navigation: ReactNode;
  isInContent?: boolean;
}> = ({ pauseAnimating, resumeAnimating, children, navigation, isInContent }) => {
  const hasNavigation = Array.isArray(navigation) && navigation.length > 1;

  return (
    <section className={classNames("hero", { "hero--content": isInContent })}>
      <div
        className="slider-wrap"
        onBlur={resumeAnimating}
        onFocus={pauseAnimating}
        onMouseEnter={pauseAnimating}
        onMouseLeave={resumeAnimating}
        onTouchEnd={resumeAnimating}
        onTouchStart={pauseAnimating}
      >
        {hasNavigation ? <div className="slider-nav">{navigation}</div> : null}
        <div className="slider">{children}</div>
      </div>
    </section>
  );
};

export default VisualHeroSliderContainer;
